import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import SuperAdminDashboard from '../views/SuperAdminDashBoard/Dashboard/index'
import SuperAdminUser from '../views/SuperAdminDashBoard/User/Index'
import SuperAdminLicense from '../views/SuperAdminDashBoard/SuperAdminLicense/index'
import SuperAdminMailServer from '../views/SuperAdminDashBoard/MailServer/Index'
import SuperAdminSettings from '../views/settings/index'
import LicenseClient from '../views/SuperAdminDashBoard/SuperAdminLicense/LicenseSidebar'
import Receivables from 'views/SuperAdminDashBoard/SuperAdminLicense/Receivables'
import Payables from 'views/SuperAdminDashBoard/SuperAdminLicense/Payables'
import TrialBalance from 'views/SuperAdminDashBoard/TrialBalance/TrialBalance'
import ChildTable from 'views/SuperAdminDashBoard/TrialBalance/ChildTable'
import LicenseSidebarParent from 'views/SuperAdminDashBoard/SuperAdminLicense/LicenseSidebarParent'
import Feedback from 'views/SuperAdminDashBoard/Feedback/feedback'
// import SyncStatsDashboard from 'views/project/ProjectDashboard/components/SyncStatsDashboard'

const SuperAdminRouting = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/app/dashboard" />} />
            <Route path="/app/dashboard" element={<SuperAdminDashboard />} />
            <Route path="/user" element={<SuperAdminUser />} />
            <Route path="/license" element={<SuperAdminLicense />} />
            <Route path="/license/:id" element={<LicenseSidebarParent />} />
            <Route path="/license/:id/receivables" element={<Receivables />} />
            <Route path="/license/:id/payables" element={<Payables />} />
            {/* <Route path="/mailserver" element={<SuperAdminMailServer />} /> */}
            <Route path="/settings" element={<SuperAdminMailServer />} />
            <Route path="/trialBalance" element={<TrialBalance />} />
            <Route path="/trialBalance/:parentGUID" element={<ChildTable />} />
            <Route path="/app/Settings" element={<SuperAdminSettings />} />
            <Route path="/feedback" element={<Feedback />} />
            {/* <Route path="/app/sync-stats" element={<SyncStatsDashboard />} /> */}
        </Routes>
    )
}

export default SuperAdminRouting
