import React, { useEffect, useState } from 'react'
import { Card } from 'components/ui'
import { useSelector } from 'react-redux'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useNavigate, useParams } from 'react-router-dom'
import TrialBalance from '../TrialBalance/TrialBalance'
import ChildTable from '../TrialBalance/ChildTable' // Make sure to provide the correct import path.
import './App.css'
const LicenseSidebar = () => {
    const { id } = useParams()
    const { parentGUID } = useParams()
    const [isLoading, setIsLoading] = useState(true) // State to track loading
    const Loader = () => (
        <div className="loader-container">
            <div
                className="loader"
                style={{ backgroundColor: '#133386' }}
            ></div>
        </div>
    )

    const navigate = useNavigate()

    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { selectedLicense, selectedManage, financialYear, trialBalance } =
        useSelector((state) => state.auth.superAdmin)
    const [teams, setTeams] = useState([])
    const [transaction, setTransaction] = useState([])


    const [receivablesAmount, setRecievablesAmount] = useState(0)
    const [payablesAmount, setPayablesAmount] = useState(0)
    const [payables, setPayables] = useState([])
    const [sundryDebtors, setSundryDebtors] = useState([])
    const [sundryCreditors, setsundryCreditors] = useState([])

    useEffect(() => {
        let filtered = selectedLicense?.TEAMS?.filter(
            (item) => item?.companyGUID == selectedManage?.GUID
        )
        setTeams(filtered)
    }, [selectedManage, selectedLicense])

    useEffect(() => {
        const getUserData = async () => {
            setIsLoading(true)
            try {
                let resp = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/getallusers/${selectedManage?.databaseName}/TRANSACTIONS/${tokenData}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'access-control-allow-credentials': 'true',
                            'access-control-allow-origin': '*',
                        },
                    }
                )
                resp = await resp.json()
                setTransaction([...resp])
            } catch (err) {
                console.log(err)
            } finally {
                // Set isLoading to false after data is fetched
                setIsLoading(false)
            }
        }
        getUserData()
    }, [selectedManage])

    const [totalFinalTransactions, settotalFinalTransactions] = useState()
    // console.log("totalFinalTransactions",totalFinalTransactions)
    const [totalCustomer, setTotalCustomer] = useState()
    const [totalVendor, setTotalVendor] = useState()
    useEffect(() => {
        const getTotalCustomer = async () => {
            setIsLoading(true)
            try {
                const bodyData = {
                   
                     db:"superDatabase",
                     statsDb: selectedManage?.databaseName,
                }
                let resp = await fetch(
                    // `${process.env.REACT_APP_BASE_URL}/transcation/getAllReceivablesPayables`,
                    `${process.env.REACT_APP_BASE_URL}/daybook/getDocumentStatsSuperAdmin`,
                    {
                        method: 'POST',
                        body: JSON.stringify(bodyData),
                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'access-control-allow-credentials': 'true',
                            'access-control-allow-origin': '*',
                        },
                    }
                )
            
                resp = await resp.json()
                // console.log("jhjh",resp)
                setTotalCustomer(resp?.customers)
                setTotalVendor(resp?.vendors)
                const totalFinalTransactions = resp['debit notes'] + resp['purchase orders'] +resp['purchases'] +resp['payments'] + resp["sales"]+resp["journals"]+resp["receipts"]+resp["credit notes"] +resp["sales orders"];
                // console.log("Total Final Transactions:", totalFinalTransactions);
                
                // Assuming setTotalFinalTransactions is a function that takes the calculated sum
                settotalFinalTransactions(totalFinalTransactions);
                const filteredData = resp.slice(0, resp.length - 2);
                // const onlyCustomerCount = resp?.pop()
                // setTotalCustomer(onlyCustomerCount)
                // console.log("jhjh",filteredData)
               
            } catch (err) {
                console.log(err)
            } finally {
                // Set isLoading to false after data is fetched
                setIsLoading(false)
            }
        }
// console.log("jhjhjhjhjjjgghghjh", totalCustomer,totalVendor)

        const getTotalVendor = async () => {
            setIsLoading(true)
            try {
                const bodyData = {
                   
                    db:"superDatabase",
                    statsDb: selectedManage?.databaseName,
                }
                let resp = await fetch(
                    // `${process.env.REACT_APP_BASE_URL}/transcation/getAllReceivablesPayables`,
                    `${process.env.REACT_APP_BASE_URL}/daybook/getDocumentStatsSuperAdmin`,
                    {
                        method: 'POST',
                        body: JSON.stringify(bodyData),
                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'access-control-allow-credentials': 'true',
                            'access-control-allow-origin': '*',
                        },
                    }
                )
                resp = await resp.json()
                const filteredData = resp.slice(0, resp.length - 2);
                // const onlyVendorCount = resp?.pop()
                // setTotalVendor(onlyVendorCount)
                setTotalVendor(filteredData.length)
            } catch (err) {
                console.log(err)
            } finally {
                // Set isLoading to false after data is fetched
                setIsLoading(false)
            }
        }
        getTotalCustomer()
        getTotalVendor()
    }, [selectedManage, financialYear])

    useEffect(() => {
        getChartsData()
    }, [selectedManage, financialYear])

    function processArrayOfObjects(arr) {
        let total = 0

        arr.forEach((obj) => {
            // Convert OUTSTANDING to a number, make it positive, and round to two decimal places
            obj.OUTSTANDING = Math.abs(parseFloat(obj.OUTSTANDING))?.toFixed(2)

            // Calculate the total
            total += parseFloat(obj.OUTSTANDING)
        })

        // Round the total to two decimal places
        total = total?.toFixed(2)

        return total
    }

    const getChartsData = async () => {
        setIsLoading(true)
        try {
            let bodyData = {
                DATABASE: selectedManage?.databaseName,
                STARTYEAR: financialYear,
                ENDYEAR: financialYear + 1,
                SELECTEDPERIOD: 15,
            }

            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/getrecievables`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            if (resp?.status == true) {
                let receivables = resp?.outstanding?.filter(
                    (item) => item?.OUTSTANDING < 0
                )
                let totalReceivables = processArrayOfObjects(receivables)
                setRecievablesAmount(totalReceivables)
                let payable = resp?.outstanding?.filter(
                    (item) => item?.OUTSTANDING > 0
                )
                let totalPayables = processArrayOfObjects(payable)
                setPayablesAmount(totalPayables)
            }
        } catch (err) {
            console.log('err', err)
        } finally {
            // Set isLoading to false after data is fetched
            setIsLoading(false)
        }
    }

    // Function to handle Receivables click
    const handleReceivablesClick = () => {
        // Add your logic here to handle the Receivables click
        navigate(`/license/${id}/receivables`)
        // alert('Receivables Clicked');
    }

    // Function to handle Payables click
    const handlePayablesClick = () => {
        // Add your logic here to handle the Payables click
        // alert('Payables Clicked');
        navigate(`/license/${id}/payables`)
    }

    if (parentGUID) {
    }
    if (isLoading) {
        return <Loader />
    }

    return (
        <div className="p-5">
            {/* <Card className="w-72 space-y-5 ">
                <div className="space-y-2 ">
                    <div className="px-5 flex items-center gap-4 py-2 hover:bg-gray-200 rounded-lg  font-bold  ">
                        <AiOutlineDatabase size={30} /> <span> Statistics</span>
                    </div>
                    <div className="px-5 flex items-center gap-4 py-2 hover:bg-gray-200 rounded-lg  font-bold  ">
                        <FaBalanceScaleLeft size={30} />{' '}
                        <span> Trial Balance</span>
                    </div>

                    <div className="px-5 flex items-center gap-4 py-2 hover:bg-gray-200 rounded-lg  font-bold">
                        <BsFillFileSpreadsheetFill size={30} />{' '}
                        <span>Balance Sheet</span>
                    </div>
                    <div className="px-5 flex items-center gap-4 py-2 hover:bg-gray-200 rounded-lg  font-bold">
                        <BsFillFileEarmarkSpreadsheetFill size={30} />{' '}
                        <span>Leadger Statement</span>
                    </div>
                    <div className="px-5 flex items-center gap-4 py-2 hover:bg-gray-200 rounded-lg  font-bold ">
                        <GiReceiveMoney size={30} /> <span> Cashflow</span>
                    </div>
                    <div className="px-5 flex items-center gap-4 py-2 hover:bg-gray-200 rounded-lg  font-bold">
                        <MdOutlineCollectionsBookmark size={30} />{' '}
                        <span>Receivable</span>
                    </div>
                    <div className="px-5 flex items-center gap-4 py-2 hover:bg-gray-200 rounded-lg  font-bold">
                        <BsPaypal size={30} /> <span> Payable</span>
                    </div>

                    <div className=" px-5 flex items-center gap-4 py-2 hover:bg-gray-200 rounded-lg  font-bold">
                        <GiProfit size={30} /> <span> Profit & Loss</span>
                    </div>
                    <div className="px-5 flex items-center gap-4 py-2 hover:bg-gray-200 rounded-lg  font-bold ">
                        <GrStackOverflow size={30} /> <span> Revenue</span>
                    </div>
                </div>
            </Card> */}
            <div>
                <Card>
                    <div className="">
                        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                            <div className="card card-border">
                                <div className="card-body">
                                    <div className="flex items-center gap-4">
                                        <span className="avatar avatar-rounded bg-cyan-100 text-cyan-600 dark:bg-cyan-500/20 dark:text-cyan-100">
                                            <span className="avatar-icon avatar-icon-55">
                                                {/* <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 20 20"
                                                    height="30px"
                                                    width="30px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                                </svg> */}

                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 20 20"
                                                    height="30px"
                                                    width="30px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <div>
                                            <div className="flex gap-1.5 items-end mb-2">
                                                <h3 className="font-bold leading-none">
                                                    {
                                                        selectedLicense
                                                            ?.companies?.length
                                                    }
                                                </h3>
                                                <p className="font-semibold">
                                                    Total Company
                                                </p>
                                            </div>
                                            {/* <p className="flex items-center gap-1">
                                                <span className="flex items-center rounded-full gap-1">
                                                    <span className="rounded-full p-1 bg-emerald-100 text-emerald-600 dar dark:ld-100 dark:bg-emerald-500/20 dark:text-emerald-100">
                                                        <svg
                                                            stroke="currentColor"
                                                            fill="none"
                                                            stroke-width="0"
                                                            viewBox="0 0 24 24"
                                                            height="1em"
                                                            width="1em"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                    <span className="font-semibold text-emerald-600">
                                                        + 5.5
                                                    </span>
                                                </span>
                                                <span>this month</span>
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-border">
                                <div className="card-body">
                                    <div className="flex items-center gap-4">
                                        <span className="avatar avatar-rounded bg-indigo-100 text-indigo-600 dark:bg-indigo-500/20 dark:text-indigo-100">
                                            <span className="avatar-icon avatar-icon-55">
                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 20 20"
                                                    height="30px"
                                                    width="30px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <div>
                                            <div className="flex gap-1.5 items-end mb-2">
                                                <h3 className="font-bold leading-none">
                                                    {/* {transaction?.length} */}{totalFinalTransactions}
                                                </h3>
                                                <p className="font-semibold">
                                                    Total Transaction
                                                </p>
                                            </div>
                                            {/* <p className="flex items-center gap-1">
                                                    <span className="flex items-center rounded-full gap-1">
                                                        <span className="rounded-full p-1 bg-emerald-100 text-emerald-600 dark0/20 dark:text-em-100 dark:bg-emerald-500/20 dark:text-emerald-100">
                                                            <svg
                                                                stroke="currentColor"
                                                                fill="none"
                                                                stroke-width="0"
                                                                viewBox="0 0 24 24"
                                                                height="1em"
                                                                width="1em"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="2"
                                                                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                                                                ></path>
                                                            </svg>
                                                        </span>
                                                        <span className="font-semibold text-emerald-600">
                                                            + 2.6
                                                        </span>
                                                    </span>
                                                    <span>this month</span>
                                                </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-border">
                                <div className="card-body">
                                    <div className="flex items-center gap-4">
                                        <span className="avatar avatar-rounded bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100">
                                            <span className="avatar-icon avatar-icon-55">
                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 20 20"
                                                    height="30px"
                                                    width="30px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <div>
                                            <div className="flex gap-1.5 items-end mb-2">
                                                <h3 className="font-bold leading-none">
                                                    {teams ? teams?.length :  0}
                                                </h3>
                                                <p className="font-semibold">
                                                    Total Team Members
                                                </p>
                                            </div>
                                            {/* <p className="flex items-center gap-1">
                                                    <span className="flex items-center rounded-full gap-1">
                                                        <span className="rounded-full p-1 text-red-600 bg-red-100 dark:text-red-100 dark:bg-red-500/20">
                                                            <svg
                                                                stroke="currentColor"
                                                                fill="none"
                                                                stroke-width="0"
                                                                viewBox="0 0 24 24"
                                                                height="1em"
                                                                width="1em"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="2"
                                                                    d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"
                                                                ></path>
                                                            </svg>
                                                        </span>
                                                        <span className="font-semibold text-red-600">
                                                            -0.7
                                                        </span>
                                                    </span>
                                                    <span>this month</span>
                                                </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-border">
                                <div className="card-body">
                                    <div className="flex items-center gap-4">
                                        <span className="avatar avatar-rounded bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100">
                                            <span className="avatar-icon avatar-icon-55">
                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 20 20"
                                                    height="30px"
                                                    width="30px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <div>
                                            <div className="flex gap-1.5 items-end mb-2">
                                                {/* <h3 className="font-bold leading-none">
                                                    {trialBalance
                                                        ? trialBalance
                                                              ?.sundryDebtors
                                                              ?.length
                                                        : '0'}
                                                </h3> */}
                                                <h3 className="font-bold leading-none">
                                                   {/* {totalCustomer
                                                        ? totalCustomer.totalCount
                                                        : '0'} */}
                                                        {totalCustomer
                                                        ? totalCustomer
                                                        : '0'}
                                                </h3>
                                                <p className="font-semibold">
                                                    Total Customers
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-border">
                                <div className="card-body">
                                    <div className="flex items-center gap-4">
                                        <span className="avatar avatar-rounded bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100">
                                            <span className="avatar-icon avatar-icon-55">
                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 20 20"
                                                    height="30px"
                                                    width="30px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <div>
                                            <div className="flex gap-1.5 items-end mb-2">
                                                {/* <h3 className="font-bold leading-none">
                                                    {trialBalance
                                                        ? trialBalance
                                                              ?.sundryCreditors
                                                              ?.length
                                                        : '0'}
                                                </h3> */}
                                                <h3 className="font-bold leading-none">
                                                    {/* {totalVendor
                                                        ? totalVendor.totalCount
                                                        : '0'} */}
                                                        {totalVendor
                                                        ? totalVendor
                                                        : '0'}
                                                </h3>
                                                <p className="font-semibold">
                                                    Total Vendors
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="bg-white rounded-lg shadow-md mt-5 border">
                <h2 className="text-2xl font-semibold p-4">Cashflow</h2>
                <div
                    className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-100"
                    onClick={handleReceivablesClick} // Add click event handler
                >
                    <div className="flex items-center">
                        <div className="rounded-full bg-green-200 w-8 h-8 flex items-center justify-center">
                            <IoIosArrowDown className="text-green-500 text-2xl" />
                        </div>
                        <span className="ml-2 text-gray-700">Customers</span>
                    </div>
                    {/* <span className="text-xl font-bold text-green-500">
                        ₹ {receivablesAmount}
                    </span> */}
                </div>
                <div
                    className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-100"
                    onClick={handlePayablesClick} // Add click event handler
                >
                    <div className="flex items-center">
                        <div className="rounded-full bg-red-200 w-8 h-8 flex items-center justify-center">
                            <IoIosArrowUp className="text-red-500 text-2xl" />
                        </div>
                        <span className="ml-2 text-gray-700">Vendors</span>
                    </div>
                    {/* <span className="text-xl font-bold text-red-500">
                        ₹{payablesAmount}
                    </span> */}
                </div>
            </div>
        </div>
    )
}

export default LicenseSidebar
