import React from 'react'
import { Avatar, Dropdown } from 'components/ui'
import withHeaderItem from 'utils/hoc/withHeaderItem'
import useAuth from 'utils/hooks/useAuth'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { HiOutlineUser, HiOutlineCog, HiOutlineLogout } from 'react-icons/hi'
import { FiActivity } from 'react-icons/fi'

const UserDropdownSuperAdmin = ({ className }) => {
    const { authority } = useSelector((state) => state.auth.user)
    const { user } = useSelector((state) => state.auth.user)
    const { signOut } = useAuth()

    const UserAvatar = (
        <div className={classNames(className, 'flex items-center gap-2')}>
            <div
                style={{ background: '#133386' }}
                className="rounded-full w-12 h-12 flex items-center justify-center text-white text-2xl"
            >
                {user
                    ? user.userName[0].toUpperCase() +
                      user.userName.split(' ')[1][0].toUpperCase()
                    : ''}
            </div>
            <div className="hidden md:block">
                <div className="text-xs capitalize">
                    {user?.isAppAdmin == true
                        ? 'Admin'
                        : user?.isSuperAdmin == true
                        ? 'Super Admin'
                        : 'User'}
                </div>
                <div className="font-bold">
                    <span>{user?.userName}</span>
                </div>
            </div>
        </div>
    )

    return (
        <div>
            <Dropdown
                menuStyle={{ minWidth: 240 }}
                renderTitle={UserAvatar}
                placement="bottom-end"
            >
                <Dropdown.Item variant="header">
                    <div className="py-2 px-3 flex items-center gap-2">
                        <div
                            style={{ background: '#133386' }}
                            className="rounded-full w-12 h-12 flex items-center justify-center text-white  text-2xl"
                        >
                            {user
                                ? user.userName[0].toUpperCase() +
                                  user.userName.split(' ')[1][0].toUpperCase()
                                : ''}
                        </div>
                        <div>
                            <div className="font-bold text-gray-900 dark:text-gray-100">
                                {user?.userName}
                            </div>
                            <div className="text-xs">{user?.email}</div>
                        </div>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item variant="divider" />
                <Dropdown.Item
                    onClick={signOut}
                    eventKey="Sign Out"
                    className="gap-2"
                >
                    <span className="text-xl opacity-50">
                        <HiOutlineLogout />
                    </span>
                    <span>Sign Out</span>
                </Dropdown.Item>
            </Dropdown>
        </div>
    )
}
export default withHeaderItem(UserDropdownSuperAdmin)
